@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../../SCSS/vars';



.Stamps {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 20px;
    margin: 60px 0;

    .Stampsbox {
        flex-shrink: 0;
        display: flex;
        gap: 20px;
        counter-reset: item;
        justify-content: space-around;
        min-width: 100%;
        animation: scroll 20s linear infinite;


    }

    @keyframes scroll {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(calc(-100% - 20px));
        }
    }
}