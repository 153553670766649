@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../../SCSS/vars';



.PopularTechnique {
    max-width: 91.667%;
    margin: 0px auto;


    .PopularTechniqueTitle {
        font-family: $fontMontserrat;
        font-weight: 600;
        font-size: 40px;
        color: #1E1E1E;
        text-align: center;
    }

    .slider-container {
        position: relative;

        .PopularArrowR {
            // position: absolute;
            // top: 303px;
            // right: -30px;
            z-index: 100;
            cursor: pointer;
        }

    }


    .slick-arrow {
        background-color: #fff;
    }

    .slick-prev {
        background-color: #Fff;
        border-top: 2.5px solid #000;
        border-left: 2.5px solid #000;
        width: 20px;
        height: 20px;
        transform: rotate(-45deg);
    }

    .slick-next {
        background-color: #Fff;
        border-top: 2.5px solid #000;
        border-left: 2.5px solid #000;
        width: 20px;
        height: 20px;
        transform: rotate(135deg);
        right: 0px;
    }

    .slick-next::before {
        content: '';
        border: none;
        font-size: 32px;
        color: #000;
        background-color: #fff;
        font-family: 700;

    }

    .slick-prev::before {
        content: '';
        border: none;
        color: #000;
        font-size: 32px;
        background-color: #fff;
    }


    .PopularTechniqueCard {
        display: flex;
        height: 517px;
        max-width: 90%;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 14px;
        box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.4);
        padding: 25px;
        position: relative;
        margin: 30px 10px;
        cursor: pointer;




        .PopularTechniqueCardName {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .PopularTechniqueName {
                font-family: $fontMontserrat;
                font-weight: 500;
                font-size: 14px;
                color: #19191BB2;
            }

            .PopularTechniqueText {
                font-family: $fontMontserrat;
                font-weight: 600;
                font-size: 22px;
                color: #19191B;
            }
        }


        .PopularTechniqueCardImg {
            width: 100%;
            height: 290px;

            img {
                width: 100%;
                height: 100%;
            }
        }




        .PopularTechniqueCardPrice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;


            .PopularTechniquePrice {
                font-family: $fontMontserrat;
                font-weight: 700;
                color: #19191BB2;
                font-size: 18px;
            }

            .PopularTechniqueBtn {
                font-family: $fontMontserrat;
                font-weight: 500;
                color: #FFCD12;
                font-size: 22px;
                background-color: #fff;
                padding: 0;
                margin: 0;
                border: none;

            }
        }
    }

}


// ============= media

@media (min-width: 1024px) and (max-width:1290px) {
    .PopularTechnique .PopularTechniqueCard {
        height: 380px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardImg {
        height: 230px;
    }

    .PopularTechnique .PopularTechniqueTitle {
        font-size: 35px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniqueBtn {
        font-size: 16px;
        // width: 110px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniquePrice {
        font-size: 16px;
    }

}

@media (min-width:768px) and (max-width:1023px) {
    .PopularTechnique .PopularTechniqueCard {
        height: 460px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardImg {
        height: 250px;
    }

    .PopularTechnique .PopularTechniqueTitle {
        font-size: 30px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardName .PopularTechniqueText {
        font-size: 18px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardName .PopularTechniqueName {
        font-size: 20px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice {
        width: 100%;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniqueBtn {
        font-size: 16px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniquePrice {
        font-size: 16px;
    }
}

@media (min-width:425px) and (max-width:767px) {
    .PopularTechnique .PopularTechniqueCard {
        height: 450px;
        padding: 20px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardImg {
        height: 250px;
    }

    .PopularTechnique button {
        background-color: #fff;
        // display: none;
    }

    .PopularTechnique .PopularTechniqueTitle {
        font-size: 25px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardName .PopularTechniqueText {
        font-size: 20px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardName .PopularTechniqueName {
        font-size: 16px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniqueBtn {
        font-size: 18px;

    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniquePrice {
        font-size: 18px;
    }
}

@media screen and (max-width:424px) {
    .PopularTechnique .PopularTechniqueCard {
        height: 450px;
        padding: 20px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardImg {
        height: 250px;
    }


    .PopularTechnique button {
        background-color: #fff;
    }

    .PopularTechnique .PopularTechniqueTitle {
        font-size: 25px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardName .PopularTechniqueText {
        font-size: 19px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardName .PopularTechniqueName {
        font-size: 18px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniqueBtn {
        font-size: 20px;
    }

    .PopularTechnique .PopularTechniqueCard .PopularTechniqueCardPrice .PopularTechniquePrice {
        font-size: 20px;
    }
}