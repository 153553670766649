.content {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	background-color: white;
	z-index: 9999;
}

.pl {
	width: 8em;
	height: 8em;
}

.pl circle {
	transform-box: fill-box;
	transform-origin: 50% 50%;
}

.pl__ring1 {
	animation: ring1_ 4s 0s ease-in-out infinite;
}

.pl__ring2 {
	animation: ring2_ 4s 0.04s ease-in-out infinite;
}

.pl__ring3 {
	animation: ring3_ 4s 0.08s ease-in-out infinite;
}

.pl__ring4 {
	animation: ring4_ 4s 0.12s ease-in-out infinite;
}

.pl__ring5 {
	animation: ring5_ 4s 0.16s ease-in-out infinite;
}

.pl__ring6 {
	animation: ring6_ 4s 0.2s ease-in-out infinite;
}

/* Animations */
@keyframes ring1_ {
	from {
		stroke-dashoffset: -376.237129776;
		transform: rotate(-0.25turn);
		animation-timing-function: ease-in;
	}

	23% {
		stroke-dashoffset: -94.247778;
		transform: rotate(1turn);
		animation-timing-function: ease-out;
	}

	46%,
	50% {
		stroke-dashoffset: -376.237129776;
		transform: rotate(2.25turn);
		animation-timing-function: ease-in;
	}

	73% {
		stroke-dashoffset: -94.247778;
		transform: rotate(3.5turn);
		animation-timing-function: ease-out;
	}

	96%,
	to {
		stroke-dashoffset: -376.237129776;
		transform: rotate(4.75turn);
	}
}

@keyframes ring2_ {
	from {
		stroke-dashoffset: -329.207488554;
		transform: rotate(-0.25turn);
		animation-timing-function: ease-in;
	}

	23% {
		stroke-dashoffset: -82.46680575;
		transform: rotate(1turn);
		animation-timing-function: ease-out;
	}

	46%,
	50% {
		stroke-dashoffset: -329.207488554;
		transform: rotate(2.25turn);
		animation-timing-function: ease-in;
	}

	73% {
		stroke-dashoffset: -82.46680575;
		transform: rotate(3.5turn);
		animation-timing-function: ease-out;
	}

	96%,
	to {
		stroke-dashoffset: -329.207488554;
		transform: rotate(4.75turn);
	}
}

@keyframes ring3_ {
	from {
		stroke-dashoffset: -288.4484661616;
		transform: rotate(-0.25turn);
		animation-timing-function: ease-in;
	}

	23% {
		stroke-dashoffset: -72.2566298;
		transform: rotate(1turn);
		animation-timing-function: ease-out;
	}

	46%,
	50% {
		stroke-dashoffset: -288.4484661616;
		transform: rotate(2.25turn);
		animation-timing-function: ease-in;
	}

	73% {
		stroke-dashoffset: -72.2566298;
		transform: rotate(3.5turn);
		animation-timing-function: ease-out;
	}

	96%,
	to {
		stroke-dashoffset: -288.4484661616;
		transform: rotate(4.75turn);
	}
}

@keyframes ring4_ {
	from {
		stroke-dashoffset: -253.9600625988;
		transform: rotate(-0.25turn);
		animation-timing-function: ease-in;
	}

	23% {
		stroke-dashoffset: -63.61725015;
		transform: rotate(1turn);
		animation-timing-function: ease-out;
	}

	46%,
	50% {
		stroke-dashoffset: -253.9600625988;
		transform: rotate(2.25turn);
		animation-timing-function: ease-in;
	}

	73% {
		stroke-dashoffset: -63.61725015;
		transform: rotate(3.5turn);
		animation-timing-function: ease-out;
	}

	96%,
	to {
		stroke-dashoffset: -253.9600625988;
		transform: rotate(4.75turn);
	}
}

@keyframes ring5_ {
	from {
		stroke-dashoffset: -225.7422778656;
		transform: rotate(-0.25turn);
		animation-timing-function: ease-in;
	}

	23% {
		stroke-dashoffset: -56.5486668;
		transform: rotate(1turn);
		animation-timing-function: ease-out;
	}

	46%,
	50% {
		stroke-dashoffset: -225.7422778656;
		transform: rotate(2.25turn);
		animation-timing-function: ease-in;
	}

	73% {
		stroke-dashoffset: -56.5486668;
		transform: rotate(3.5turn);
		animation-timing-function: ease-out;
	}

	96%,
	to {
		stroke-dashoffset: -225.7422778656;
		transform: rotate(4.75turn);
	}
}

@keyframes ring6_ {
	from {
		stroke-dashoffset: -203.795111962;
		transform: rotate(-0.25turn);
		animation-timing-function: ease-in;
	}

	23% {
		stroke-dashoffset: -51.05087975;
		transform: rotate(1turn);
		animation-timing-function: ease-out;
	}

	46%,
	50% {
		stroke-dashoffset: -203.795111962;
		transform: rotate(2.25turn);
		animation-timing-function: ease-in;
	}

	73% {
		stroke-dashoffset: -51.05087975;
		transform: rotate(3.5turn);
		animation-timing-function: ease-out;
	}

	96%,
	to {
		stroke-dashoffset: -203.795111962;
		transform: rotate(4.75turn);
	}
}