@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container {
	max-width: 1440px;
	margin: auto;
}

@media (max-width: 1440px) {
	.container {
		width: 100%;
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	max-width: 91.667%;
	margin: 60px auto;

	.block_media {
		display: flex;
		flex-direction: column;
		width: 650px;
		height: 690px;
		position: relative;
		font-size: 14px;
		color: #000;
		margin: 0;
		padding: 0;
	}

	& .block_text {
		display: flex;
		flex-direction: column;
		max-width: 650px;
		height: auto;
		gap: 17px;

		& .top_media_text {
			display: flex;
			flex-direction: column;
			height: auto;
			width: auto;
			gap: 17px;

			& h2 {
				color: rgb(25, 25, 27);
				font-family: 'Montserrat', sans-serif;
				font-size: 42px;
				font-weight: 700;
				line-height: 52px;
				text-align: left;
			}

			& h3 {
				color: rgb(25, 25, 27);
				font-family: 'Montserrat', sans-serif;
				font-size: 24px;
				font-weight: 700;
				line-height: 52px;
				text-align: left;
				display: flex;
				flex-direction: column;
				width: max-content;

				.line {
					width: 100%;
					height: 5px;
					background-color: rgb(255, 205, 18);
					margin-top: -8px;
				}
			}

			& .block_nav {
				display: flex;
				height: auto;
				flex-wrap: wrap;
				gap: 32px;

				& button {
					flex: 0 1 28%;
					width: 191px;
					height: 46px;
					order: 0;
					flex-grow: 0;
					padding: 10px;

					box-sizing: border-box;
					border: 1px solid rgb(0, 0, 0);
					border-radius: 7px;
					background-color: #fff;

					display: flex;
					justify-content: center;
					align-items: center;

					color: rgb(25, 25, 27);
					font-family: 'Montserrat', sans-serif;
					font-size: 20px;
					font-weight: 500;
					line-height: 130%;
					text-align: center;
					transition: 0.5s ease;
				}
			}
		}

		& .top_bottom_text {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 17px;
			height: auto;
			max-width: 100%;

			& .detail_texts {
				width: 100%;
				min-height: 350px;
				font-family: 'Montserrat', sans-serif;
				color: rgba(25, 25, 27, 0.8);
				font-size: 16px;
				font-weight: 400;
				line-height: 26px;
				white-space: break-spaces;
			}

			& .btn {
				max-width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 20px;

				& button {
					width: 168px;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;

					border-radius: 7px;
					background: rgb(255, 205, 18);
					color: rgb(30, 30, 30);
					font-family: 'Montserrat', sans-serif;
					font-size: 20px;
					font-weight: 500;
					line-height: 120%;
					text-align: left;
				}

				& .icons_network {
					width: 36.67px;
					height: 36.67px;
					color: rgb(25, 25, 27);
				}
			}
		}
	}
}

.error {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 50px;
	color: red;
	font-weight: 900;
}

//=======================================

@media (max-width: 1200px) {
	.wrapper .block_media {
		width: 550px;
		height: 550px;
	}
}

@media (max-width: 1100px) {
	.wrapper .block_text .top_media_text h2 {
		font-size: 1.5rem;
	}
}

@media (max-width: 902px) {
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@media (max-width: 834px) {
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@media (max-width: 768px) {
	.wrapper .block_text .top_bottom_text .btn button {
		width: 70%;
	}

	.wrapper .block_text .top_media_text h3 .line {
		margin-top: 6px;
	}

	.wrapper .block_media {
		width: 420px;
		height: 430px;
	}

	.wrapper .block_text .top_media_text {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.wrapper .block_text .top_media_text h2 {
		color: rgb(25, 25, 27);
		font-family: 'Montserrat', sans-serif;
		font-size: 20px;
		font-weight: 700;
		line-height: 120%;
		letter-spacing: -0.4px;
		text-align: left;
	}

	.wrapper .block_text .top_media_text h3 {
		color: rgb(25, 25, 27);
		font-family: 'Montserrat', sans-serif;
		font-size: 20px;
		font-weight: 700;
		line-height: 120%;
		letter-spacing: -0.4px;
		text-align: left;
		margin-bottom: 32px;
	}
}

@media (max-width: 480px) {
	.wrapper .block_text .top_bottom_text .btn button {
		width: 235px;
		height: 60px;
		/* Автолейаут */
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 18px 38px 18px 38px;
	}

	.wrapper .block_media {
		width: 320px;
		height: 320px;
	}

	.wrapper .block_text .top_media_text .block_nav {
		display: flex;
		flex-wrap: nowrap;
		gap: 6px;
	}

	.wrapper .block_text .top_media_text .block_nav button {
		width: 100%;
		height: 36px;
		color: rgb(25, 25, 27);
		font-size: 12px;
		font-weight: 500;
	}
}

@media (max-width: 375px) {
	.container {
		width: 91.4666667%;
	}

	.wrapper .block_media {
		width: 300px;
		height: 300px;
	}

	.wrapper .block_text .top_media_text .block_nav {
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
	}
}