@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.content {
	width: 985px;
	height: auto;
	min-height: 340px;
	display: flex;
	align-items: center;
	gap: 33px;
	box-sizing: border-box;
	border-radius: 14px;

	box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);

	& .image {
		width: 402px;
		height: 340px;

		& img {
			width: 402px;
			height: 340px;
			object-fit: cover;
			background-position: center;
			border-radius: 14px 0 0 14px;
		}
	}

	& .content_text {
		padding-left: 12px 39px 12px 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		min-height: 316px;
		padding: 12px 0;

		& .content_block_top {
			display: flex;
			flex-direction: column;
			gap: 6px;

			& h2 {
				font-family: 'Montserrat', sans-serif;
				font-weight: 700;
				font-size: 32px;
				line-height: 131%;
				color: rgb(25, 25, 27);
				cursor: pointer;
			}

			& h2:hover {
				text-decoration: underline;
			}

			& .description {
				color: rgba(25, 25, 27, 0.4);
				font-family: 'Montserrat', sans-serif;
				font-size: 16px;
				font-weight: 400;
				line-height: 26px;
				letter-spacing: 0px;
				text-align: left;
				// width: 100%;
				// height: auto;
			}

			& .year {
				color: rgba(25, 25, 27, 0.8);
				font-family: 'Montserrat', sans-serif;
				font-size: 16px;
				font-weight: 600;
				line-height: 26px;
				letter-spacing: 0px;
				text-align: left;
			}
		}

		& .content_block_bottom {
			display: flex;
			flex-direction: column;
			gap: 12px;

			& .instock {
				color: rgb(25, 25, 27);
				font-family: 'Montserrat', sans-serif;
				font-size: 18px;
				font-weight: 700;
				line-height: 26px;
				letter-spacing: 0px;
				text-align: left;
				margin-top: 10px;
				// text-transform: capitalize;
			}

			.price {
				font-family: 'Montserrat', sans-serif;
				font-weight: 800;
				font-size: 24px;
				line-height: 108%;
				letter-spacing: 0.08em;
				color: #000;
				position: relative;
				display: inline-block;
				width: max-content;
				margin-bottom: 12px;
			}

			.price::before,
			.price::after {
				content: '';
				position: absolute;
				bottom: -8px;
				width: 100%;
				height: 5px;
				background-color: rgb(255, 205, 18);
			}

			.price::before {
				left: 0;
			}

			.price::after {
				right: 0;
			}

			@media (max-width: 768px) {
				.price {
					font-size: 18px;
					/* Уменьшаем размер текста для меньших экранов */
				}
			}

			& button {
				border-radius: 7px;

				background: rgb(255, 205, 18);
				width: 168px;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;

				color: rgb(30, 30, 30);
				font-family: 'Montserrat', sans-serif;
				font-size: 20px;
				font-weight: 500;
				line-height: 120%;
				letter-spacing: 0%;
			}
		}
	}
}

//=============================
@media (max-width: 1440px) {
	.content {
		width: 100%;
	}
}

@media (max-width: 995px) {
	.content .content_text .content_block_top h2 {
		font-size: 1.5rem;
	}
}

@media (max-width: 954px) {
	.content .content_text .content_block_top h2 {
		font-size: 1.3rem;
	}
}

@media (max-width: 768px) {
	.content {
		flex-direction: column;
		gap: 20px;

		.image {
			width: 100%;
			height: auto;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.content_text {
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 33px;

			.content_block_top {
				h2 {
					font-size: 24px;
				}

				.description {
					font-size: 14px;
				}

				.year {
					font-size: 16px;
					font-weight: 600;
				}
			}

			.content_block_bottom {
				.instock {
					font-size: 16px;
					font-weight: 700;
				}

				.price {
					font-size: 24px;
				}

				button {
					width: 100%;
					height: 50px;
					font-size: 16px;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.content .image img {
		border-radius: 14px;
	}

	.content .content_text {
		padding: 21px 6px 6px;
	}

	.content {
		box-sizing: border-box;
		border-radius: 14px;

		box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
		background: rgb(255, 255, 255);
	}
}

@media (max-width: 375px) {
	.content {
		.content_text {
			.content_block_top {
				h2 {
					font-size: 20px;
				}

				.description {
					font-size: 12px;
				}

				.year {
					font-size: 12px;
				}
			}

			.content_block_bottom {
				.instock {
					font-size: 12px;
				}

				.price {
					font-size: 18px;
				}

				button {
					font-size: 14px;
				}
			}
		}
	}
}

@media (max-width: 375px) {
	.container {
		width: 91.4666667%;
	}

	// .content {
	// 	flex-direction: column;
	// 	gap: 20px;

	// 	.image {
	// 		width: 100%;
	// 		height: 300px;

	// 		img {
	// 			width: 100%;
	// 			height: 100%;
	// 			object-fit: cover;
	// 			object-position: center;
	// 		}
	// 	}
	// }
}
