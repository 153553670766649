.wrapper {
	position: fixed;
	z-index: 10;
	top: 185px;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: flex-end;
}

.container {
	position: relative;
	max-width: 400px;
	padding: 0 2.5rem;
	overflow: auto;
}

@media (max-width:768px) {
	.wrapper {
		top: 150px;
	}

	.container {
		padding: 0 1.5rem;
	}
}