.swiper {
    width: 100%;
    height: 100%;
}

.swiperSlide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiperSlide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiperSlide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 14px;
    box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 40px;
}

.mySwiper {
    height: 25%;
    box-sizing: border-box;
    padding: 0;
}

.mySwiper .swiperSlide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.mySwiper .swiperSlide-thumb-active {
    opacity: 1;
}


.ifrm {
    width: 100%;
    height: 113px;
    z-index: -1;
    position: relative;
}

.ifrmB {
    width: 100%;
    height: 100%;

}


@media (max-width:1290px) {
    .ifrm {
        height: 83.91px;
    }
}

@media (max-width:1024px) {
    .ifrm {
        height: 75px;
    }
}

@media (max-width:768px) {
    .ifrm {
        height: 60px;
    }
}