@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	min-width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px;
	background-color: rgba(0, 0, 0, 0.5);

	.container {
		max-width: 550px;
		min-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #ffcd12;
		gap: 20px;
		border-radius: 14px;
		box-shadow: 10px 11px 18px -6px rgba(0, 0, 0, 0.53);
		-webkit-box-shadow: 10px 11px 18px -6px rgba(0, 0, 0, 0.53);
		-moz-box-shadow: 10px 11px 18px -6px rgba(0, 0, 0, 0.53);
		padding: 0 20px;

		h1 {
			font-size: 2rem;
			color: black;
			font-family: 'Montserrat', sans-serif;
			text-align: center;
		}

		.icon {
			width: 50px;
			height: 50px;
		}
	}
}
