@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 998;
	min-width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba(107, 107, 107, 0.5);

	& .container {
		display: inline-flex;
		flex-direction: column;
		border-radius: 14px;
		padding: 24px;
		max-width: 632px;
		max-height: 681px;
		width: 100%;
		height: 100%;
		overflow-y: auto;

		border-radius: 20px;
		background: #fff;
		z-index: 999;
	}

	& form {
		display: flex;
		flex-direction: column;
		gap: 24px;

		& .title {
			color: rgb(25, 25, 27);
			font-family: 'Montserrat', sans-serif;
			font-size: 32px;
			font-weight: 700;
			line-height: 120%;
			text-align: left;
		}

		& .input_block {
			display: flex;
			flex-direction: column;
			gap: 6px;
			justify-content: baseline;
			align-items: baseline;

			& h3 {
				color: rgb(25, 25, 27);
				font-family: 'Montserrat', sans-serif;
				font-size: 18px;
				font-weight: 500;
				line-height: 120%;
				text-align: left;
			}

			& input {
				border: 1px solid rgb(199, 199, 199);
				border-radius: 7px;
				padding: 12px 6px;
				width: 100%;
				height: 43px;
				font-family: 'Montserrat', sans-serif;

				& input::placeholder {
					font-family: 'Montserrat', sans-serif;
					font-weight: 500;
					font-size: 16px;
					line-height: 120%;
					color: rgb(199, 199, 199);
				}
			}
		}

		& .btn {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& button {
			display: flex;
			padding: 18px 38px;
			justify-content: center;
			align-items: center;
			gap: 10px;

			border-radius: 14px;
			background: #ffcd12;

			color: var(--Black, #1e1e1e);
			font-size: 24px;
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 500;
			line-height: 130%;
		}
	}
}

.form_header {
	display: flex;
	gap: 10px;

	& .close_icon {
		width: 40px;
		height: 40px;
		display: none;
	}
}

.error {
	color: red;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 120%;
	text-align: left;
}

.error_s {
	color: red;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 120%;
	text-align: center;
}

//=========================================
@media (max-width: 768px) {
	.wrapper form button {
		width: 100%;
	}

	.wrapper .container {
		overflow-y: auto;
	}
}

@media (max-width: 655px) {
	.form_header .close_icon {
		display: block;
	}

	.wrapper {
		padding: 0 40px;
	}

	.wrapper {
		max-height: 502px;
		overflow-y: auto;
	}

	.wrapper .container {
		max-height: 562px;
		overflow-y: auto;
	}
}

@media (max-width: 520px) {
	.wrapper form {
		gap: 10px;
	}

	.wrapper {
		max-height: 502px;
		overflow-y: auto;
	}

	.wrapper .container {
		max-height: 562px;
		overflow-y: auto;
	}

	.wrapper .container form .title {
		font-size: 1.5rem;
		text-align: center;
	}

	.wrapper form button {
		padding: 18px;
		font-size: 20px;
	}

	.wrapper .container {
		max-height: 650px;
		overflow-y: auto;
	}

	.wrapper {
		padding: 30px;
	}
}

@media (max-width: 375px) {
	.wrapper .container form .title {
		font-size: 1.2rem;
		text-align: center;
	}

	.wrapper .container form button {
		font-size: 20px;
		width: 100%;
	}

	.wrapper form button {
		padding: 10px;
		font-size: 20px;
	}
}