.special_not_found {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 50px;
    color: red;
    font-weight: 700;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}