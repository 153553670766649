@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../SCSS/vars';

.home {
    margin: 0px 0 10px 0;

    .container {
        max-width: 1440px;
        margin: auto;
    }

    @media(max-width:1440px) {
        .container {
            width: 100%;
        }
    }
}

@media (max-width:768px) {
    .home {
        margin-top: 20px;
    }
}