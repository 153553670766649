@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../SCSS/vars';


.wrapSpe {
    width: 330px;
    position: relative;
    height: auto;
    background-color: #Fff;
    box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 0;
    padding: 20px;
    border-radius: 7px;
}


#panel1d-header {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 14px;
    border: none;
    text-transform: uppercase;
    margin-left: 10px;
}

#panel2d-header {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    margin-left: 10px;
}

#panel3d-header {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    margin-left: 10px;
}

#panel4d-header {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    margin-left: 10px;

}

#panel5d-header {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    margin-left: 10px;

}


.findBtnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .frindBtn {
        background-color: #ffcd12;
        text-align: center;
        font-size: 18px;
        color: #000;
        font-family: $fontMontserrat;
        font-weight: 500;
        height: 40px;
        width: 100%;
        border-radius: 7px;
    }
}




.AccordionDetailsInp {
    max-height: 200px;
    overflow-y: auto;
    border: none;
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar {
        width: 10px;
    }


    &::-webkit-scrollbar-track {
        background: #ffcd12;
        border-radius: 2px;

    }

    &::-webkit-scrollbar-thumb {
        // border-radius: 4px;
        background: #ffcd12;
        width: 3px;
    }
}