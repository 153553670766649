@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container {
	max-width: 1440px;
	margin: 20px auto;
}

@media (max-width: 1440px) {
	.container {
		width: 100%;
	}
}

.wrapper_cover {
	.SettingBurger {
		max-width: 91.667%;
		display: none;
		margin: 0 auto;
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	max-width: 91.667%;
	margin: 20px auto;
	position: relative;
	gap: 20px;

	& .block {
		flex: 0 1 73%;

		& .pagination {
			margin-top: 50px;
		}
	}

	& .block {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
}

.error {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 50px;
	color: red;
	font-weight: 900;
}

.unfind_thing {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 50px;
	color: red;
	font-weight: 900;
}

.pagination {
	z-index: -1;
	display: flex;
	justify-content: center;
}

//==================================
@media (max-width: 1290px) {
	.wrapper {
		display: flex;
		justify-content: center;
	}

	.wrapper .block {
		flex: 0 1 100%;
	}

	.accor {
		display: none;
	}

	.wrapper_cover .SettingBurger {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.wrapper .block {
		order: 1;
	}
}

@media (max-width: 375px) {
	.container {
		width: 91.4666667%;
	}
}