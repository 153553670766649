@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../SCSS/vars';

.SEquipmentRightCardItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border: 1px solid var(--light-gray, #C7C7C7);
    border-radius: 14px;
    box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 340px;


    .specialDetailImg {
        width: 46%;


        img {
            width: 100%;
            border-radius: 14px 0 0 14px;
        }
    }

    .SECardTitle {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin: 10px 0 0 0;
        gap: 15px;

        .TitleTypeName {
            font-family: $fontMontserrat;
            font-weight: 600;
            font-size: 16px;
            color: rgba(25, 25, 27, 0.8);
        }


        .name {
            font-family: $fontMontserrat;
            font-weight: 700;
            font-size: 32px;
            color: #1E1E1E;
        }

        .description {
            font-family: $fontMontserrat;
            font-weight: 400;
            font-size: 16px;
            color: #19191B66;
        }

        .existence {
            font-family: $fontMontserrat;
            font-weight: 700;
            font-size: 18px;
            color: #19191B;
        }

        .brandName {
            font-family: $fontMontserrat;
            font-weight: 400;
            font-size: 10px;
            color: #19191B66;
        }

        .PriceCard {
            display: flex;
            flex-direction: column;
            gap: 2px;
            width: max-content;
            // margin-bottom: 6px;

            .price {
                font-family: $fontMontserrat;
                font-weight: 800;
                font-size: 24px;
                color: #19191B;
            }

            .linePrice {
                width: 100%;
                height: 5px;
                border-radius: 7px;
                background-color: #FFCD12;
            }
        }

        @media (max-width:768px) {
            .PriceCard {
                margin-bottom: 10px;
            }
        }


        .year {
            font-family: $fontMontserrat;
            font-weight: 600;
            font-size: 16px;
            color: rgba(25, 25, 27, 0.8);
        }

        .btnOrder {
            background-color: #FFCD12;
            border: none;
            border-radius: 7px;
            font-family: $fontMontserrat;
            font-weight: 500;
            font-size: 20px;
            width: 35%;
            height: 60px;
            text-align: center;
        }
    }

}

@media (min-width: 1024px) and (max-width:1290px) {
    .SEquipmentRightCardItems .SECardTitle .TitleTypeName {
        font-size: 16px;
    }

    .SEquipmentRightCardItems .specialDetailImg {
        width: 47%;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .SEquipmentRightCardItems .SECardTitle .TitleTypeName {
        font-size: 16px;
    }

    .SEquipmentRightCardItems {
        height: 280px;
    }

    .SEquipmentRightCardItems .specialDetailImg img {
        height: 280px;
    }

    .SEquipmentRightCardItems .SECardTitle {
        gap: 10px;
    }

    .SEquipmentRightCardItems .SECardTitle .btnOrder {
        width: 120px;
        font-size: 16px;
        height: 40px;
    }


}


@media (max-width:767px) {
    .SEquipmentRightCardItems .SECardTitle .TitleTypeName {
        font-size: 20px;
    }

    .SEquipmentRightCardItems .SECardTitle .name {
        font-size: 25px;
        font-weight: 700;
    }

    .SEquipmentRightCardItems {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        border-radius: 15px;
    }


    .SEquipmentRightCardItems .specialDetailImg {
        width: 100%;
        height: 450px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 14px;
        }
    }

    .SEquipmentRightCardItems .SECardTitle {
        gap: 6px;
        width: 100%;
        padding: 10px;
    }

    .SEquipmentRightCardItems .SECardTitle .year {
        font-size: 16px;
        font-weight: 600;
    }

    .SEquipmentRightCardItems .SECardTitle .TitleTypeName {
        font-size: 16px;
        font-weight: 600;
    }

    .SEquipmentRightCardItems .SECardTitle .existence {
        font-size: 16px;
        margin: 30px 0 10px;
    }

    .SEquipmentRightCardItems .SECardTitle .price {
        font-size: 28px;
    }

    .SEquipmentRightCardItems .SECardTitle .description {
        font-size: 16px;
    }


    .SEquipmentRightCardItems .SECardTitle .btnOrder {
        width: 100%;
        height: 50px;
        font-size: 22px;
    }

}

@media (max-width:500px) {

    .SEquipmentRightCardItems .specialDetailImg {
        width: 100%;
        height: 380px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 14px;
        }
    }
}

@media (max-width:424px) {
    .SEquipmentRightCardItems .SECardTitle .TitleTypeName {
        font-size: 16px;
    }


    .SEquipmentRightCardItems .SECardTitle .name {
        font-size: 25px;
    }

    .SEquipmentRightCardItems {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        border-radius: 15px;
    }

    .SEquipmentRightCardItems .specialDetailImg {
        width: 100%;
        height: 300px;

    }

    .SEquipmentRightCardItems .specialDetailImg img {
        width: 100%;
        border-radius: 0;
        border: none;
        border-radius: 15px;
    }

    .SEquipmentRightCardItems .SECardTitle {
        gap: 10px;
        width: 95%;
        margin: 10px 10px;
    }

    .SEquipmentRightCardItems .SECardTitle .year {
        font-size: 16px;
    }

    .SEquipmentRightCardItems .SECardTitle .existence {
        font-size: 16px;
    }

    .SEquipmentRightCardItems .SECardTitle .price {
        font-size: 25px;
    }

    .SEquipmentRightCardItems .SECardTitle .description {
        font-size: 16px;
    }


    .SEquipmentRightCardItems .SECardTitle .btnOrder {
        width: 100%;
        height: 40px;
        font-size: 18px;
    }

}