@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../SCSS/vars';


#panel1-headerr {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 16px;
}

#panel2-headerr {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 16px;
    width: 250px;
}

#panel3-headerr {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 16px;
    width: 250px;
}

#panel4-headerr {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 16px;
    width: 250px;

}

#panel5-headerr {
    font-family: $fontMontserrat;
    font-weight: 700;
    font-size: 16px;
    width: 250px;

}



.SettingBurgerMenu {
    position: fixed;
    z-index: 10;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.AcorSetting {
    width: 270px;
}

.SettingBox {
    position: relative;
    width: 300px;
    padding: 16px;
    overflow: auto;
    background-color: #fff;
    box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    top: -50px;
    margin-right: 30px;
    margin-top: 10px;

    .filtrSearch {
        position: absolute;
        bottom: 22px;
        left: 28px;
    }

    .searchButtton {
        width: 99%;
        height: 33px;
        border: 1px solid black;
        margin: 0px auto;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        padding: 5px 10px 5px 35px;
        font-size: 16px;
        color: #000;
        font-family: $fontMontserrat;
        font-weight: 500;

    }

    .Sclose_block {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 5px 0;

        .Sclose_icon {
            width: 18px;
            height: 18px;
        }
    }

}


@media (max-width:768px) {
    .SettingBurgerMenu {
        top: 40px;
    }
}