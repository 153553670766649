@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../SCSS/vars';

*,
*::after,
*::before {
	margin: 0;
	box-sizing: border-box;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	color: darkgrey;
}

.container {
	max-width: 1440px;
	margin: auto;
}

@media (max-width: 1440px) {
	.container {
		width: 100%;
	}
}

.headerTopBox {
	background-color: #ffcd12;
	padding: 5px;

	.headerTop {
		width: 800px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.headerTopItems {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;

			.HWITF {
				width: 24px;

				img {
					width: 100%;
				}
			}

			.HWITFF {
				width: 27px;

				img {
					width: 100%;
				}
			}

			.headerTopItemsIcons {
				cursor: pointer;
			}

			h3 {
				font-family: $fontMontserrat;
				font-weight: 600;
				font-size: 14px;
				color: #000;
			}

			h4 {
				font-family: $fontMontserrat;
				font-weight: 400;
				font-size: 14px;
				color: #000;
			}
		}
	}
}

.nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 8vh;
	max-width: 91.667%;
	margin: 15px auto 15px;
	position: relative;

	.search_input {
		display: none;
		position: absolute;
		top: 60px;
		right: 4px;
		width: 64%;
		height: 30px;
		border-radius: 7px;
		border: 1px solid black;
		padding: 10px;
		z-index: 200;
	}

	@media (max-width: 768px) {
		.search_input {
			display: block;
		}
	}

	@media (max-width: 500px) {
		.search_input {
			display: block;
			width: 63%;
		}
	}
}

.HLogo {
	width: 160px;

	img {
		width: 100%;
	}
}

@media (max-width: 1290px) {
	.HLogo {
		width: 130px;
	}
}

.title {
	text-transform: uppercase;
	font-family: $fontMontserrat;
	font-weight: 700;
	color: #19191b;
	font-size: 40px;
}

.nav_link {
	font-family: $fontMontserrat;
	font-weight: 500;
	font-size: 1.2rem;
	color: #19191b;
}

.active_nav {
	position: relative;
	font-family: $fontMontserrat;
	font-weight: 700;
	font-size: 1.2rem;
	color: #19191b;
}

.active_nav:after {
	display: block;
	position: absolute;
	left: 0;
	top: 35px;
	width: 100%;
	height: 5px;
	border-radius: 6px;
	background-color: #ffcd12;
	content: '';
}

.nav_menu {
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 3rem;
}

.nav_toogler div {
	width: 1.7rem;
	height: 0.15rem;
	margin: 0.4rem;
	background-color: #000;
	transition: 0.4s ease-in;
}

.nav_toogler {
	cursor: pointer;
	display: none;
}

.navTopItems {
	display: none;

	.HWITF {
		width: 28px;

		img {
			width: 100%;
		}
	}

	.HWITFF {
		width: 32px;

		img {
			width: 100%;
		}
	}
}

.navbarSearch {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 320px;

	.Language {
		width: 77px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3px;

		.ru,
		.ru_active,
		.ch,
		.ch_active {
			width: 36px;
			height: 36px;
			border-radius: 50%;
			border: 1px solid black;
			font-family: $fontMontserrat;
			font-size: 14px;
			padding: 11px 2px;
			font-weight: 500;
			text-align: center;
			color: #000;
			cursor: pointer;
		}

		.ru_active,
		.ch_active {
			background-color: #ffcd12;
			border: none;
		}
	}

	.Searchcontainer {
		position: relative;
		width: 60px;
		height: 45px;
		transition: ease-in 0.5s;
		background: #fff;
		border-radius: 25px;
		border: 4px solid #fff;
		padding: 5px;

		.searchCard {
			display: none;
		}

		.sr {
			display: none;
		}

		.searchNotFound {
			display: none;
		}

		.Viewmore {
			display: none;
		}

		.searchInput {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 40px;
			display: none;
			border-radius: 20px;
			padding: 0 20px;
			border: 1px solid black;
			font-size: 16px;
			font-family: $fontMontserrat;
			font-weight: 500;
			color: #000;
			text-transform: capitalize;
		}

		.searchIcon {
			padding: 10px;
			position: absolute;
			width: 40px;
			height: 40px;
			top: 0;
			right: 0;
			border-radius: 50%;
			transform: all 11s;
			background-color: #ffcd12;
		}

		&:hover {
			width: 200px;
			cursor: pointer;

			.searchBox {
				position: absolute;
				top: 40px;
				left: -130px;
				width: 320px;
				height: 400px;
				background-color: #fff;
				z-index: 100;
				box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
				border: 1px solid var(--light-gray, #c7c7c7);
				padding: 5px;

				.searchNotFound {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					font-size: 20px;
					font-family: $fontMontserrat;
					color: red;
					font-weight: 700;
				}

				.Viewmore {
					display: block;
					font-family: $fontMontserrat;
					font-weight: 500;
					color: #000;
					font-size: 18px;
					position: absolute;
					bottom: 5px;
					right: 80px;
					text-align: center;
				}

				.searchCard {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 10px;

					.searchImg {
						width: 150px;
						height: 100px;
					}

					.searchCardRigth {
						display: flex;
						flex-direction: column;
						gap: 20px;
						flex: 0 1 48%;

						.searchName {
							font-family: $fontMontserrat;
							font-weight: 500;
							color: #000;
							font-size: 16px;
						}

						.searchExistence {
							font-family: $fontMontserrat;
							font-weight: 500;
							color: #000;
							font-size: 14px;
						}

						.searchPrice {
							font-family: $fontMontserrat;
							font-weight: 500;
							color: #ffcd12;
							font-size: 14px;
						}
					}
				}
			}

			.sr {
				display: none;
			}
		}

		&:hover .searchInput {
			display: block;
		}

		&:hover .searchIcon {
			display: none;
		}
	}
}

.MobileSearchContainer {
	display: none;
}

// ================

@media (min-width: 1024px) and (max-width: 1290px) {
	header .container .nav .nav_menu .nav_link {
		font-size: 17px;
		font-weight: 600;
	}

	header .container .nav .navbarSearch {
		width: 270px;
	}

	header .container .nav .navbarSearch .vipIcon {
		width: 44px;
	}

	.active_nav {
		position: relative;
		font-family: $fontMontserrat;
		font-weight: 700;
		font-size: 1.4rem;
		color: #19191b;
	}

	.active_nav:after {
		display: block;
		position: absolute;
		left: 0;
		top: 35px;
		width: 100%;
		height: 5px;
		border-radius: 6px;
		background-color: #ffcd12;
		content: '';
	}

	header .container .nav .searchBox {
		position: absolute;
		top: 0;
		left: 0;
	}

	.nav_menu {
		gap: 20px;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.headerTop {
		max-width: 680px;
	}

	header .container .nav .navbarSearch .searchBox {
		position: relative;
		top: 36px;
		left: -155px;
		width: 300px;
	}

	header .container .nav .navbarSearch {
		width: 200px;
	}

	header .container .nav .navbarSearch .Searchcontainer:hover {
		width: 160px;
	}

	.nav_menu {
		gap: 1rem;
	}

	header .container .nav .nav_menu {
		margin-left: 10px;
	}

	header .container .nav .nav_menu .nav_link {
		font-size: 12px;
		font-weight: 700;
	}

	header .container .nav .navbarSearch .vipIcon {
		width: 34px;
	}

	header .container .nav .MobileSearchContainer {
		display: none;
	}
}

@media (min-width: 425px) and (max-width: 767px) {
	.headerTopBox {
		display: none;
	}

	header .container .nav .MobileSearchBox .searchBox {
		position: relative;
		top: 41px;
		left: -120px;
		width: 300px;
	}

	header .container .nav .navbarSearch {
		display: none;
	}

	header .container .nav .navTopItems {
		gap: 5px;
		z-index: -1;
	}

	header .container .nav .navbarSearch {
		width: 150px;
	}

	header .container .nav .MobileSearchContainer {
		display: block;
	}

	header
		.container
		.nav
		.MobileSearchContainer
		.MobileSearchBox
		.mobileSearchInput:hover {
		width: 100%;
		position: absolute;
		right: 0;
		transition: 0.4s ease-in;
	}

	.nav_toogler {
		display: block;
	}

	.nav_menu {
		position: fixed;
		top: 10vh;
		right: 0;
		width: 40%;
		height: 40vh;
		background-color: #fdfdfd;
		flex-direction: column;
		transform: translateX(100%);
		transition: 0.5s ease-in;
		z-index: 1;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
	}

	.nav__active {
		transform: translateX(0%);
	}

	.toogle .line1 {
		transform: rotate(-45deg) translate(-3px, 3px);
	}

	.toogle .line2 {
		opacity: 0;
	}

	.toogle .line3 {
		transform: rotate(45deg) translate(-9px, -10px);
	}

	.navTopItems {
		display: flex;
		align-items: center;
		gap: 20px;
	}
}

@media screen and (max-width: 424px) {
	.headerTopBox {
		display: none;
	}

	header .container .nav {
		max-width: 92%;
	}

	header
		.container
		.nav
		.MobileSearchContainer
		.MobileSearchBox
		.mobileSearchInput:hover {
		width: 100%;
		position: absolute;
		right: 0;
		transition: 0.4s ease-in;
		top: 40px;
	}

	header .container .nav .navTopItems {
		gap: 1px;
		z-index: -1;
	}

	header .container .nav .MobileSearchBox .searchBox {
		position: relative;
		top: 81px;
		left: -60px;
		width: 300px;
	}

	header .container .nav .navbarSearch {
		display: none;
	}

	.nav_toogler {
		display: block;
	}

	.MobileSearchContainer {
		display: block;
	}

	.nav_menu {
		position: fixed;
		top: 13.6vh;
		right: 0;
		width: 40%;
		height: 40vh;
		background-color: #fdfdfd;
		flex-direction: column;
		transform: translateX(100%);
		transition: 0.5s ease-in;
		z-index: 2;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
	}

	.nav__active {
		transform: translateX(0%);
	}

	.toogle .line1 {
		transform: rotate(-45deg) translate(-3px, 5px);
	}

	.toogle .line2 {
		opacity: 0;
	}

	.toogle .line3 {
		transform: rotate(45deg) translate(-9px, -10px);
	}

	.navTopItems {
		display: flex;
		align-items: center;
		gap: 20px;
	}
}
