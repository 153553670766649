@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container {
	max-width: 1440px;
	margin: auto;
}

@media (max-width: 1440px) {
	.container {
		width: 100%;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	max-width: 91.667%;
	margin: 60px auto;

	& .content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		& .contact {
			display: flex;
			flex-direction: column;
			gap: 46px;

			& .block_address {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 24px;
				max-width: 420px;

				& .block_text {
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 26px;
					width: auto;
					gap: 11px;

					& .icons_address {
						width: 18px;
						height: 18px;
						color: black;
					}

					& .span_text {
						color: rgb(25, 25, 27);
						font-family: 'Montserrat', sans-serif;
						font-size: 20px;
						font-weight: 600;
						line-height: 130%;
						text-align: center;
					}

					& .usual_text {
						color: rgb(25, 25, 27);
						font-family: 'Montserrat', sans-serif;
						font-size: 20px;
						font-weight: 400;
						line-height: 130%;
						text-align: center;
					}
				}
			}

			& .block_phone_number {
				display: flex;
				width: 109px;
				justify-content: space-between;
				align-items: center;

				& .icons_network {
					width: 28px;
				}

				& .icons_networkk {
					width: 32px;
				}
			}
		}
	}
}

.contact_side {
	display: flex;
	flex-direction: column;
	min-height: 400px;
	justify-content: space-between;

	h2 {
		max-width: 633px;
		height: auto;
		color: rgb(25, 25, 27);
		font-family: 'Montserrat', sans-serif;
		font-size: 24px;
		font-weight: 700;
		line-height: 120%;
		letter-spacing: 0px;
		text-align: left;
	}
}

//=======================================
@media (max-width: 1100px) {
	.wrapper .content .contact .block_address .block_text .usual_text1 {
		display: none;
	}
}

@media (max-width: 992px) {
	.content {
		display: flex;
		flex-direction: column;
	}

	.contact_side {
		order: 2;
	}

	.contact_form {
		order: 1;
	}


	.contact_side h2 {
		text-align: center;
	}

	.contact_side {
		margin-top: 40px;
	}



	.wrapper .content .contact .block_address {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.wrapper .content .contact .block_phone_number {
		margin: 0 auto;
		display: flex;
		align-items: center;
	}

	.wrapper .contact {
		display: flex;
		align-items: center;

	}

	.contact_side {

		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

	}

}

@media (max-width: 834px) {
	.wrapper .content .contact {
		display: none;
	}


	.contact_side {
		display: none;
	}
}


@media (max-width: 375px) {
	.container {
		width: 91.4666667%;
	}

	.contact_side {
		display: block;
	}

	.contact_side {
		display: flex;
		flex-direction: column;
		min-height: 170px;

	}

	.contact_side h2 {
		display: none;
	}

	.wrapper .content .contact .block_phone_number {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		gap: 7px;
	}

	.wrapper .content .contact {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.wrapper .content .contact .block_address .block_text {
		display: flex;
		justify-content: flex-start;
		width: 100%;
	}

	.wrapper .content .contact .block_address .block_text .span_text {
		font-size: 17px;
		font-weight: 600;
	}

	.wrapper .content .contact .block_address .block_text .usual_text {
		font-size: 17px;
		font-weight: 400;
	}

	.wrapper .content .contact .block_address .block_text .usual_text1 {
		display: none;
	}

	.wrapper .content .contact .block_address .block_text .icons_address {
		width: 18px;
		height: 18px;
	}
}

@media (max-width: 320px) {}