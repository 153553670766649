@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	max-height: 850px;
	box-sizing: border-box;
	border-radius: 7px;
	box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
	position: sticky;
	top: 0;
	padding: 16px;
	background-color: #fff;

	.close_block {
		width: 100%;
		height: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		display: none;

		.close_icon {
			width: 18px;
			height: 18px;
		}
	}

	form {
		display: flex;
		flex-direction: column;

		.btn_form {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& button {
			margin-top: 10px;
			display: flex;
			padding: 10px;
			justify-content: center;
			align-items: center;
			gap: 10px;

			border-radius: 7px;
			background: #ffcd12;

			color: rgb(30, 30, 30);
			font-family: 'Montserrat', sans-serif;
			font-size: 18px;
			font-weight: 500;
			line-height: 120%;
			letter-spacing: 0%;
			width: 100%;
		}
	}

	& h2 {
		color: rgb(0, 0, 0);
		font-family: 'Montserrat', sans-serif;
		font-size: 14px;
		font-weight: 700;
		line-height: 26px;
		text-transform: uppercase;
	}

	.inputs_scroll {
		max-height: 200px;
		overflow-y: auto;
	}

	.inputs {
		display: flex;
		flex-direction: column;
		gap: 13px;

		& label {
			display: flex;
			gap: 14px;

			color: rgb(0, 0, 0);
			font-family: 'Montserrat', sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 120%;

			& input {
				color: rgb(0, 0, 0);
				font-family: 'Montserrat', sans-serif;
				font-size: 14px;
				font-weight: 500;
				line-height: 120%;
			}
		}
	}

	.input_row {
		display: flex;
		align-items: center;
		gap: 12px;

		& label {
			display: flex;
			align-items: center;
			gap: 12px;

			color: rgb(0, 0, 0);
			font-family: 'Montserrat', sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 120%;

			& input {
				box-sizing: border-box;
				border: 1px solid rgb(0, 0, 0);
				border-radius: 3.5px;
				width: 60px;
				height: 20px;
			}
		}
	}
}

.input_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;

	box-sizing: border-box;
	border: 1px solid rgb(25, 25, 27);
	border-radius: 7px;
	padding: 5px 8px;

	.search_icon {
		width: 20px;
		height: 20px;
	}

	& input {
		color: rgb(25, 25, 27);
		font-family: 'Montserrat', sans-serif;
		font-size: 18px;
		font-weight: 400;
		line-height: 130%;
		letter-spacing: 0%;
	}
}


@media (max-width: 1290px) {
	.wrapper .close_block {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}