@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../../SCSS/vars';


.YearFilter {
    display: flex;
    gap: 10px;
}

.YearInput {
    border: 1px solid rgba(36, 35, 35, 0.831);
    width: 60px;
    height: 20px;
    border-radius: 4px;
    margin-left: 10px;
}

.YearLabel {
    margin-right: 10px;
    font-size: 14px;
    font-family: $fontMontserrat;
    font-weight: 500;
    color: #000;
}

@media (max-width:1290px) {
    .YearInput {
        width: 60px;
    }
}