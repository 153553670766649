@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../SCSS/vars';

footer {
	height: 20vh;
	background-color: #fff;
	// z-index: -1;

	.container {
		max-width: 1440px;
		margin: auto;
	}

	@media (max-width: 1440px) {
		.container {
			width: 100%;
		}
	}

	.footerBox {
		max-width: 91.667%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;


		.Flogo {
			width: 160px;

			img {
				width: 100%;
			}
		}


		.footerMenu {
			display: flex;
			justify-content: space-between;
			gap: 10px;

			.footerItemsPhone {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 15px;

				.phoneNumberTitle {
					font-family: $fontMontserrat;
					color: #000;
					font-weight: 600;
					font-size: 16px;
				}
			}

			.footerItemsAddress {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 15px;

				.cityTitle {
					font-family: $fontMontserrat;
					color: #000;
					font-weight: 600;
					font-size: 16px;
				}

				h4 {
					font-family: $fontMontserrat;
					font-weight: 400;
					font-size: 14px;
					color: #000;
				}
			}

			.footerItems {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 7px;

				.FwfIt {
					width: 25px;

					img {
						width: 100%;
					}

				}

				.FwfItT {
					width: 28px;

					img {
						width: 100%;
					}

				}



			}
		}
	}
}

.footerBoxTop {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	margin-top: 28px;
	padding-bottom: 30px;

	.PrivacyPolicyTitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 12px;

		.PrivacyPolicyTitleItem {
			font-family: $fontMontserrat;
			font-weight: 400;
			color: #000;
			font-size: 16px;
			cursor: pointer;
		}
	}

	.FooterBottomTitle {
		text-align: center;

		.FooterBottomTitleItem {
			font-family: $fontMontserrat;
			font-weight: 400;
			color: #000;
			font-size: 16px;
		}
	}
}



@media (min-width: 1024px) and (max-width:1290px) {}

@media (min-width:768px) and (max-width:1023px) {
	footer .container .footerBox .footerMenu {
		flex-wrap: wrap;
		justify-content: center;
	}

}

@media (max-width:767px) {
	footer .container .footerBox .footerMenu {
		flex-direction: column;
		justify-content: center;
	}

	footer .container .footerBox {
		flex-direction: column;
		// justify-content: center;
	}

	footer .container .footerBox .footerItems {
		gap: 5px;
	}

	footer .container .footerBoxTop .PrivacyPolicyTitle {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}