@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
	padding: 0;
	margin: 0;
	border: 0;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside,
section {
	display: block;
}

html,
body {
	height: 100%;
	min-width: 320px;
}

body {
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	font-family: 'Montserrat', sans-serif;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
	font-family: 'Montserrat', sans-serif;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	text-decoration: none;
	font-family: 'Montserrat', sans-serif;
}

a:hover {
	text-decoration: none;
	font-family: 'Montserrat', sans-serif;
}

ul li {
	list-style: none;
	font-family: 'Montserrat', sans-serif;
}

img {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
	font-family: 'Montserrat', sans-serif;
}

/*--------------------*/
