@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import '../../../SCSS/vars';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    min-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(107, 107, 107, 0.5);
    cursor: pointer;

    & .container {
        display: inline-flex;
        flex-direction: column;
        border-radius: 14px;
        padding: 24px;
        width: 932px;
        height: 60vh;
        // z-index: 999;
        border-radius: 20px;
        background: #fff;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 10px;

        }


        &::-webkit-scrollbar-track {
            background: rgba(128, 128, 128, 0.534);
            border-radius: 4px;

        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: #ffcd12;
            width: 5px;
        }

    }


    .form_header {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .title {
            font-size: 28px;
            color: #000;
            font-weight: 500;
            font-family: $fontMontserrat;
            margin-bottom: 10px;
        }

        & .close_icon {
            width: 30px;
            height: 30px;
            // display: none;
            cursor: pointer;
        }
    }

    .privacy_policy_paragraf {
        font-size: 16px;
        color: #000;
        font-weight: 400;
        font-family: $fontMontserrat;
    }


}

@media (min-width:768px) and (max-width:1023px) {
    .wrapper .container {
        width: 600px;
    }

    .wrapper .container .form_header .title {
        font-size: 22px;
    }

}

@media (max-width:767px) {
    .wrapper .container {
        width: 350px;
    }

    .wrapper .container .form_header .title {
        font-size: 16px;
        font-weight: 700;
    }

}

@media (max-width:423px) {
    .wrapper .container {
        width: 320px;
    }

    .wrapper .container .form_header .title {
        font-size: 14px;
        font-weight: 700;
    }

}