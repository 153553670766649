@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../SCSS/vars';


.Burger {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.BurgerMenu {
    width: 280px;
    height: 350px;
    background-color: #fff;
    position: relative;
    position: fixed;
    top: 10px;
    right: 10px;
    box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    animation: nav 0.4s ease-in normal none 1 running;

    .close_icon {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
    }

    .nav_link {
        color: #000;
        font-family: 'Courier New', Courier, monospace;
        font-weight: 600;
        font-size: 20px;
    }



    .burgerTitle {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .Language {
            // width: 77px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;

            .ru,
            .ru_active,
            .ch,
            .ch_active {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                border: 1px solid black;
                font-family: $fontMontserrat;
                font-size: 14px;
                padding: 11px 2px;
                font-weight: 500;
                text-align: center;
                color: #000;
                cursor: pointer;
            }

            .ru_active,
            .ch_active {
                background-color: #ffcd12;
                border: none;
            }



        }

        .headerTopItems {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            .HWITF {
                width: 24px;

                img {
                    width: 100%;
                }

            }

            .HWITFF {
                width: 28px;

                img {
                    width: 100%;
                }

            }
        }
    }

    @media (max-width:768px) {
        .BurgerMenu {
            width: 260px;

            .nav_item {
                color: #000;
                width: 200px;
                height: 50px;
            }

            .nav_link {
                color: #000;
                font-family: 'Courier New', Courier, monospace;
                font-weight: 600;
                font-size: 20px;
            }

            .active {
                color: #bbad10;
            }
        }
    }

    @media (max-width:450px) {
        .BurgerMenu {
            width: 260px;
        }
    }


}

@keyframes nav {
    0% {
        right: -50%;
    }

    100% {
        right: 5%;
    }
}