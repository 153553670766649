@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Josefin+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@200;400;500;600;700&family=Prompt:wght@400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap');
@import '../../../SCSS/vars';


.MobileSearchBox {
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .search_input {
        position: absolute;
        top: 50px;
        right: -145px;
        width: 100%;
        height: 30px;
        border-radius: 7px;
        border: 1px solid black;
        padding: 10px;

    }

    .SearchMobil {
        border-radius: 50%;
        background-color: #ffcd12;
        padding: 8px;
        width: 33px;
        height: 33px;
    }

    .searchMobileBox {
        position: absolute;
        top: 92px;
        right: -45px;
        width: 280px;
        height: 400px;
        background-color: #fff;
        z-index: 100;
        box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
        border: 1px solid var(--light-gray, #C7C7C7);
        padding: 5px;



        .searchCardMobile {
            width: 100%;
            height: auto;

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .searchImg {
                width: 130px;
                height: 90px;
            }

            .searchCardRigth {
                display: flex;
                flex-direction: column;
                gap: 12px;
                flex: 0 1 48%;

                .searchName {
                    font-family: $fontMontserrat;
                    font-weight: 500;
                    color: #000;
                    font-size: 16px;
                }

                .searchExistence {
                    font-family: $fontMontserrat;
                    font-weight: 500;
                    color: #000;
                    font-size: 14px;
                }

                .searchPrice {
                    font-family: $fontMontserrat;
                    font-weight: 500;
                    color: #ffcd12;
                    font-size: 14px;
                }
            }


        }

        .searchNotFound {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: 20px;
            font-family: $fontMontserrat;
            color: red;
            font-weight: 700;

        }

        .Viewmore {
            display: block;
            font-family: $fontMontserrat;
            font-weight: 500;
            color: #000;
            font-size: 18px;
            position: absolute;
            bottom: 5px;
            right: 80px;
            text-align: center;


        }
    }

}

@media (max-width:424px) {
    .MobileSearchBox .searchMobileBox {
        position: absolute;
        top: 92px;
        right: -10px;
        width: 270px;
        height: 400px;
    }
}