@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.container {
	max-width: 1440px;
	height: auto;
	margin: auto;
}
.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 91.667%;
	margin: 60px auto;
	gap: 60px;

	& .main_content {
		display: flex;
		flex-direction: column;
		gap: 14px;

		h1 {
			color: rgb(30, 30, 30);
			font-size: 40px;
			font-weight: 600;
			line-height: 130%;
			font-family: 'Montserrat', sans-serif;
		}

		p {
			color: rgb(30, 30, 30);
			font-size: 20px;
			font-weight: 500;
			line-height: 130%;
			text-align: left;
			font-family: 'Montserrat', sans-serif;
		}
	}

	& .block_content {
		display: flex;
		flex-direction: column;
		gap: 30px;

		& .tor_block {
			& img {
				width: 602px;
				height: 410px;
				object-position: center;
				object-fit: cover;
				border-radius: 14px 0 0 14px;
			}
		}

		& .bottom_block {
			& img {
				width: 602px;
				height: 410px;
				object-position: center;
				object-fit: cover;
				border-radius: 0 14px 14px 0;
			}
		}

		.tor_block,
		.bottom_block {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: auto;
			box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.25);
			border-radius: 14px;
			background-color: #fff;

			.text_wrapper {
				padding: 86px 35px;

				.block_text {
					display: flex;
					flex-direction: column;
					gap: 32px;

					h2 {
						color: rgba(25, 25, 27, 0.7);
						font-family: 'Montserrat', sans-serif;
						font-size: 14px;
						font-weight: 700;
						line-height: 26px;
						letter-spacing: 2px;
						text-transform: uppercase;
					}

					p {
						color: rgb(25, 25, 27);
						font-size: 16px;
						font-weight: 400;
						line-height: 26px;
						letter-spacing: 0px;
						text-align: left;
						font-family: 'Montserrat', sans-serif;
					}
				}
			}
		}
	}
}

//================================================
@media (max-width: 1440px) {
	.container {
		width: 100%;
	}
}

@media (max-width: 1200px) {
	.tor_block,
	.bottom_block {
		img {
			min-width: 400px;
		}
	}

	.wrapper .block_content .tor_block .text_wrapper {
		padding: 47px 16px;
	}
}

@media (max-width: 992px) {
	.tor_block,
	.bottom_block {
		img {
			min-width: 323px;
		}
	}
}
@media (max-width: 768px) {
	.wrapper .main_content h1 {
		color: rgb(30, 30, 30);
		font-family: 'Montserrat', sans-serif;
		font-size: 24px;
		font-weight: 600;
		line-height: 130%;
	}
	.wrapper .main_content p {
		color: rgb(30, 30, 30);
		font-family: 'Montserrat', sans-serif;
		font-size: 18px;
		font-weight: 500;
		line-height: 130%;
		text-align: left;
	}

	.wrapper .block_content .tor_block .text_wrapper {
		padding: 35px 10px 16px;
	}

	.wrapper .block_content .tor_block .text_wrapper .block_text {
		display: flex;
		flex-direction: column;
		grid-area: 32px;
	}

	.wrapper .block_content .tor_block .text_wrapper .block_text p {
		text-align: center;
		color: rgb(25, 25, 27);
		font-family: 'Montserrat', sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 26px;
	}
	.wrapper .block_content .tor_block .text_wrapper .block_text h2 {
		color: rgba(25, 25, 27, 0.7);
		font-family: 'Montserrat', sans-serif;
		font-size: 14px;
		font-weight: 700;
		line-height: 26px;
		letter-spacing: 2px;
		text-align: center;
		text-transform: uppercase;
	}
	.wrapper .block_content .bottom_block .text_wrapper {
		padding: 35px 10px 16px;
	}

	.wrapper .block_content .bottom_block .text_wrapper .block_text {
		display: flex;
		flex-direction: column;
		grid-area: 32px;
	}

	.wrapper .block_content .bottom_block .text_wrapper .block_text p {
		text-align: center;
	}

	.transport {
		max-width: 100%;
	}
	.tor_block,
	.bottom_block {
		// min-height: 70vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		img {
			width: 100%;
			border-radius: 14px;
		}

		h2 {
			text-align: center;
		}
	}
	.bottom_block {
		.text_wrapper {
			order: 2;
		}
		img {
			order: 1;
		}
	}

	.wrapper .block_content .tor_block img {
		border-radius: 14px;
	}
	.wrapper .block_content .bottom_block img {
		border-radius: 14px;
	}
}
